import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import { HEADER_TYPES } from '../constants/headerTypes'
import Inner from '../components/Inner'
import Container from '../components/Container'
import Tags from '../components/Tags/Tags'
import { BG_TYPES } from '../constants/bgTypes'
import { URL_PATHS } from '../constants/urlPaths'

const TagsTemplate = ({ pageContext }) => {
  const { tagsList } = pageContext

  const tags = tagsList.map(({ node: { name, slug } }) => ({
    url: `${URL_PATHS.TAG}/${slug}`,
    tag: name,
  }))

  return (
    <Layout headerType={HEADER_TYPES.alt} headerDarkLogo>
      <SEO title="Tags" />
      <Container
        bg={BG_TYPES.lightGrey}
        mt={['4.875rem', '', '0']}
        pt={['1.875rem', '', '2.625rem']}
        pb={['2.875rem']}
      >
        <Inner>
          <Tags {...{ tags }} />
        </Inner>
      </Container>
    </Layout>
  )
}

export default TagsTemplate
